ace.define("ace/mode/ksql_highlight_rules", ["require", "exports", "module", "ace/lib/oop", "ace/mode/text_highlight_rules"], function (require, exports, module) {
  "use strict";

  var oop = require("../lib/oop");
  var TextHighlightRules = require("./text_highlight_rules").TextHighlightRules;
  var KsqlHighlightRules = function KsqlHighlightRules() {
    var keywords = "EMIT|CHANGES|SELECT|FROM|AS|DISTINCT|WHERE|WITHIN|WINDOW|GROUP|BY|HAVING|LIMIT|AT|OR|AND|IN|NOT|EXISTS|BETWEEN|LIKE|IS|INTEGER|DATE|TIME|TIMESTAMP|INTERVAL|YEAR|MONTH|DAY|HOUR|MINUTE|SECOND|MILLISECOND|YEARS|MONTHS|DAYS|HOURS|MINUTES|SECONDS|MILLISECONDS|ZONE|TUMBLING|HOPPING|SIZE|ADVANCE|CASE|WHEN|THEN|ELSE|END|JOIN|FULL|OUTER|INNER|LEFT|RIGHT|ON|PARTITION|STRUCT|WITH|VALUES|CREATE|TABLE|TOPIC|STREAM|STREAMS|INSERT|DELETE|INTO|DESCRIBE|EXTENDED|PRINT|EXPLAIN|ANALYZE|TYPE|TYPES|CAST|SHOW|LIST|TABLES|TOPICS|QUERY|QUERIES|TERMINATE|LOAD|COLUMNS|COLUMN|PARTITIONS|FUNCTIONS|FUNCTION|DROP|TO|RENAME|ARRAY|MAP|SET|RESET|SESSION|SAMPLE|EXPORT|CATALOG|PROPERTIES|BEGINNING|UNSET|RUN|SCRIPT|DECIMAL|KEY|CONNECTOR|CONNECTORS|SINK|SOURCE|IF";
    var builtinConstants = "FALSE|NULL|TRUE";
    var builtinFunctions = "ABS|ARRAYCONTAINS|CEIL|CONCAT|DATETOSTRING|ELT|EXTRACTJSONFIELD|FIELD|FLOOR|GEO_DISTANCE|IFNULL|LCASE|LEN|MASK|MASK_KEEP_LEFT|MASK_KEEP_RIGHT|MASK_LEFT|MASK_RIGHT|RANDOM|ROUND|SPLIT|STRINGTODATE|STRINGTOTIMESTAMP|SUBSTRING|TIMESTAMPTOSTRING|TRIM|UCASE|URL_DECODE_PARAM|URL_ENCODE_PARAM|URL_EXTRACT_FRAGMENT|URL_EXTRACT_HOST|URL_EXTRACT_PARAMETER|URL_EXTRACT_PATH|URL_EXTRACT_PORT|URL_EXTRACT_PROTOCOL|URL_EXTRACT_QUERY|COLLECT_LIST|COLLECT_SET|COUNT|HISTOGRAM|MAX|MIN|SUM|TOPK|TOPKDISTINCT|WindowStart|WindowEnd";
    var dataTypes = "BOOLEAN|INTEGER|INT|BIGINT|DOUBLE|VARCHAR|STRING|ARRAY|MAP|STRUCT";
    var keywordMapper = this.createKeywordMapper({
      "support.function": builtinFunctions,
      "keyword": keywords,
      "constant.language": builtinConstants,
      "storage.type": dataTypes
    }, "identifier", true);
    this.$rules = {
      start: [{
        token: "comment",
        regex: "--.*$"
      }, {
        token: "comment",
        start: "/\\*",
        end: "\\*/"
      }, {
        token: "string",
        // " string
        regex: '".*?"'
      }, {
        token: "string",
        // ' string
        regex: "'.*?'"
      }, {
        token: "constant.numeric",
        // float
        regex: "[+-]?\\d+(?:(?:\\.\\d*)?(?:[eE][+-]?\\d+)?)?\\b"
      }, {
        token: keywordMapper,
        regex: "[a-zA-Z_$][a-zA-Z0-9_$]*\\b"
      }, {
        token: "keyword.operator",
        regex: "\\+|\\-|\\/|\\/\\/|%|<@>|@>|<@|&|\\^|~|<|>|<=|=>|==|!=|<>|="
      }, {
        token: "paren.lparen",
        regex: "[\\(]"
      }, {
        token: "paren.rparen",
        regex: "[\\)]"
      }, {
        token: "text",
        regex: "\\s+"
      }]
    };
    this.normalizeRules();
  };
  KsqlHighlightRules.metaData = {
    fileTypes: ["ksql"],
    name: "ksql",
    scopeName: "source.ksql"
  };
  oop.inherits(KsqlHighlightRules, TextHighlightRules);
  exports.KsqlHighlightRules = KsqlHighlightRules;
});
ace.define("ace/mode/folding/cstyle", ["require", "exports", "module", "ace/lib/oop", "ace/range", "ace/mode/folding/fold_mode"], function (require, exports, module) {
  "use strict";

  var oop = require("../../lib/oop");
  var Range = require("../../range").Range;
  var BaseFoldMode = require("./fold_mode").FoldMode;
  var FoldMode = exports.FoldMode = function (commentRegex) {
    if (commentRegex) {
      this.foldingStartMarker = new RegExp(this.foldingStartMarker.source.replace(/\|[^|]*?$/, "|" + commentRegex.start));
      this.foldingStopMarker = new RegExp(this.foldingStopMarker.source.replace(/\|[^|]*?$/, "|" + commentRegex.end));
    }
  };
  oop.inherits(FoldMode, BaseFoldMode);
  (function () {
    this.foldingStartMarker = /(\{|\[)[^\}\]]*$|^\s*(\/\*)/;
    this.foldingStopMarker = /^[^\[\{]*(\}|\])|^[\s\*]*(\*\/)/;
    this.singleLineBlockCommentRe = /^\s*(\/\*).*\*\/\s*$/;
    this.tripleStarBlockCommentRe = /^\s*(\/\*\*\*).*\*\/\s*$/;
    this.startRegionRe = /^\s*(\/\*|\/\/)#?region\b/;
    this._getFoldWidgetBase = this.getFoldWidget;
    this.getFoldWidget = function (session, foldStyle, row) {
      var line = session.getLine(row);
      if (this.singleLineBlockCommentRe.test(line)) {
        if (!this.startRegionRe.test(line) && !this.tripleStarBlockCommentRe.test(line)) return "";
      }
      var fw = this._getFoldWidgetBase(session, foldStyle, row);
      if (!fw && this.startRegionRe.test(line)) return "start"; // lineCommentRegionStart

      return fw;
    };
    this.getFoldWidgetRange = function (session, foldStyle, row, forceMultiline) {
      var line = session.getLine(row);
      if (this.startRegionRe.test(line)) return this.getCommentRegionBlock(session, line, row);
      var match = line.match(this.foldingStartMarker);
      if (match) {
        var i = match.index;
        if (match[1]) return this.openingBracketBlock(session, match[1], row, i);
        var range = session.getCommentFoldRange(row, i + match[0].length, 1);
        if (range && !range.isMultiLine()) {
          if (forceMultiline) {
            range = this.getSectionRange(session, row);
          } else if (foldStyle != "all") range = null;
        }
        return range;
      }
      if (foldStyle === "markbegin") return;
      var match = line.match(this.foldingStopMarker);
      if (match) {
        var i = match.index + match[0].length;
        if (match[1]) return this.closingBracketBlock(session, match[1], row, i);
        return session.getCommentFoldRange(row, i, -1);
      }
    };
    this.getSectionRange = function (session, row) {
      var line = session.getLine(row);
      var startIndent = line.search(/\S/);
      var startRow = row;
      var startColumn = line.length;
      row = row + 1;
      var endRow = row;
      var maxRow = session.getLength();
      while (++row < maxRow) {
        line = session.getLine(row);
        var indent = line.search(/\S/);
        if (indent === -1) continue;
        if (startIndent > indent) break;
        var subRange = this.getFoldWidgetRange(session, "all", row);
        if (subRange) {
          if (subRange.start.row <= startRow) {
            break;
          } else if (subRange.isMultiLine()) {
            row = subRange.end.row;
          } else if (startIndent == indent) {
            break;
          }
        }
        endRow = row;
      }
      return new Range(startRow, startColumn, endRow, session.getLine(endRow).length);
    };
    this.getCommentRegionBlock = function (session, line, row) {
      var startColumn = line.search(/\s*$/);
      var maxRow = session.getLength();
      var startRow = row;
      var re = /^\s*(?:\/\*|\/\/|--)#?(end)?region\b/;
      var depth = 1;
      while (++row < maxRow) {
        line = session.getLine(row);
        var m = re.exec(line);
        if (!m) continue;
        if (m[1]) depth--;else depth++;
        if (!depth) break;
      }
      var endRow = row;
      if (endRow > startRow) {
        return new Range(startRow, startColumn, endRow, line.length);
      }
    };
  }).call(FoldMode.prototype);
});
ace.define("ace/mode/folding/sqlserver", ["require", "exports", "module", "ace/lib/oop", "ace/range", "ace/mode/folding/cstyle"], function (require, exports, module) {
  "use strict";

  var oop = require("../../lib/oop");
  var Range = require("../../range").Range;
  var BaseFoldMode = require("./cstyle").FoldMode;
  var FoldMode = exports.FoldMode = function () {};
  oop.inherits(FoldMode, BaseFoldMode);
  (function () {
    this.foldingStartMarker = /(\bCASE\b|\bBEGIN\b)|^\s*(\/\*)/i;
    this.startRegionRe = /^\s*(\/\*|--)#?region\b/;
    this.getFoldWidgetRange = function (session, foldStyle, row, forceMultiline) {
      var line = session.getLine(row);
      if (this.startRegionRe.test(line)) return this.getCommentRegionBlock(session, line, row);
      var match = line.match(this.foldingStartMarker);
      if (match) {
        var i = match.index;
        if (match[1]) return this.getBeginEndBlock(session, row, i, match[1]);
        var range = session.getCommentFoldRange(row, i + match[0].length, 1);
        if (range && !range.isMultiLine()) {
          if (forceMultiline) {
            range = this.getSectionRange(session, row);
          } else if (foldStyle != "all") range = null;
        }
        return range;
      }
      if (foldStyle === "markbegin") return;
      return;
    };
    this.getBeginEndBlock = function (session, row, column, matchSequence) {
      var start = {
        row: row,
        column: column + matchSequence.length
      };
      var maxRow = session.getLength();
      var line;
      var depth = 1;
      var re = /(\bCASE\b|\bBEGIN\b)|(\bEND\b)/i;
      while (++row < maxRow) {
        line = session.getLine(row);
        var m = re.exec(line);
        if (!m) continue;
        if (m[1]) depth++;else depth--;
        if (!depth) break;
      }
      var endRow = row;
      if (endRow > start.row) {
        return new Range(start.row, start.column, endRow, line.length);
      }
    };
  }).call(FoldMode.prototype);
});
ace.define("ace/mode/ksql", ["require", "exports", "module", "ace/lib/oop", "ace/mode/text", "ace/mode/ksql_highlight_rules", "ace/mode/folding/sqlserver"], function (require, exports, module) {
  "use strict";

  var oop = require("../lib/oop");
  var TextMode = require("./text").Mode;
  var KsqlHighlightRules = require("./ksql_highlight_rules").KsqlHighlightRules;
  var FoldMode = require("./folding/sqlserver").FoldMode;
  var Mode = function Mode() {
    this.HighlightRules = KsqlHighlightRules;
    this.foldingRules = new FoldMode();
  };
  oop.inherits(Mode, TextMode);
  (function () {
    this.lineCommentStart = "--";
    this.$id = "ace/mode/ksql";
    this.getCompletions = function (state, session, pos, prefix) {
      var keywords = this.$keywordList || this.$createKeywordList();
      return keywords.map(function (word) {
        return {
          ignoreCase: true,
          name: word,
          value: word,
          upperCaseValue: word.toUpperCase(),
          score: 1,
          meta: "keyword"
        };
      });
    };
  }).call(Mode.prototype);
  exports.Mode = Mode;
});